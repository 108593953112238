var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-card', [_c('b-overlay', [_c('b-row', [_vm.isAdminGudang && _vm.myGudang ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("Lokasi Gudang: "), _c('v-select', {
    attrs: {
      "options": _vm.otherGudangs,
      "label": "text"
    },
    model: {
      value: _vm.gudangutama,
      callback: function ($$v) {
        _vm.gudangutama = $$v;
      },
      expression: "gudangutama"
    }
  })], 1)]) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', [_c('strong', [_vm._v("Lokasi asal "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("(*)")])])]), _c('v-select', {
    attrs: {
      "options": _vm.optbloks,
      "label": "text"
    },
    on: {
      "change": _vm.getPenyimpananBarang
    },
    model: {
      value: _vm.blokasal,
      callback: function ($$v) {
        _vm.blokasal = $$v;
      },
      expression: "blokasal"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', [_c('strong', [_vm._v("Tujuan Mutasi")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("(*)")])]), _c('v-select', {
    attrs: {
      "options": _vm.optbloks,
      "label": "text"
    },
    on: {
      "change": _vm.getPenyimpananBarang
    },
    model: {
      value: _vm.blok,
      callback: function ($$v) {
        _vm.blok = $$v;
      },
      expression: "blok"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', [_c('label', [_c('strong', [_vm._v("Scan Produk "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("(*)")])])]), _c('b-form-tags', {
    attrs: {
      "placeholder": "Scan barcode disini...",
      "separator": " ",
      "input-id": "tags-basic"
    },
    on: {
      "input": function ($event) {
        return _vm.getPenyimpananBarang();
      }
    },
    model: {
      value: _vm.caribarang,
      callback: function ($$v) {
        _vm.caribarang = $$v;
      },
      expression: "caribarang"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Daftar Mutasi")]), _c('b-table', {
    attrs: {
      "small": "",
      "bordered": "",
      "striped": "",
      "responsive": "",
      "fields": _vm.mutationFields,
      "items": _vm.mutations
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(barang)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-") + " ")]), _vm._v(" "), _c('br')];
      }
    }, {
      key: "cell(lokasi)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.getLokasi(item)) + " ")];
      }
    }, {
      key: "cell(tujuan)",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.form.tujuan ? _vm.form.tujuan.text : "-") + " ")];
      },
      proxy: true
    }, {
      key: "cell(tujuan_stok_id)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.getLokasi2(item.tujuan_stok_id)) + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        }), item.jumlah > item.jumlahAwal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Jumlah mutasi melebihi stok dari gudang asal")]) : _vm._e()];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('strong', [_vm._v(_vm._s(item.barang ? item.barang.satuan.satuan : "-"))])];
      }
    }, {
      key: "cell(satuan2)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('strong', [_vm._v(_vm._s(item.barang ? item.barang.satuan.satuan : "-"))])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref8) {
        var index = _ref8.index;
        return [_c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.mutations.splice(index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1)];
      }
    }])
  })], 1)], 1), _c('section', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit2($event);
      }
    }
  }, [_vm._v(" Simpan Mutasi ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }