<template>
  <main>
    <b-card>
      <b-overlay>
        <b-row>
          <b-col v-if="isAdminGudang && myGudang" cols="12" class="mb-2">
            <h5>Lokasi Gudang:
              <v-select :options="otherGudangs" v-model="gudangutama" label="text" />
            </h5>
            <!-- {{ otherGudangs }} -->
          </b-col>
          <b-col sm="12" md="6">
            <!-- Tujuan Gudang -->
            <b-form-group>
              <label><strong>Lokasi asal <span class="text-danger">(*)</span></strong></label>
              <v-select :options="optbloks" v-model="blokasal" label="text" @change="getPenyimpananBarang" />
            </b-form-group>
            <!-- / -->
          </b-col>
          <b-col sm="12" md="6">
            <!-- Tujuan Gudang -->
            <b-form-group>
              <label><strong>Tujuan Mutasi</strong><span class="text-danger">(*)</span></label>
              <v-select :options="optbloks" v-model="blok" label="text" @change="getPenyimpananBarang" />
            </b-form-group>
            <!-- / -->
          </b-col>
          <b-col sm="12" md="12">
            <!-- Pilih Barang -->
            <b-form-group>
              <label><strong>Scan Produk <span class="text-danger">(*)</span></strong></label>
              <!-- <v-select :options="barangs" :reduce="(option) => option.value" v-model="form.barang_id" label="text" /> -->
              <b-form-tags placeholder="Scan barcode disini..." separator=" " v-model="caribarang" input-id="tags-basic"
                class="" @input="getPenyimpananBarang()" />
              <!-- <p>Value: {{ caribarang }}</p> -->
            </b-form-group>
            <!-- / -->
          </b-col>

          <!-- List Stok Barang -->
          <!-- <b-col cols="6" class="mt-2">
            <h5 class="mb-1">Daftar Stok Barang</h5>

            <b-table small bordered striped responsive :fields="fields" :items="availableStocks">
              <template #cell(checkbox)="{ item }">
                <b-button class="rounded-full" variant="outline-info" rounded size="sm" @click="addBarang(item)">
                  <feather-icon icon="PlusCircleIcon"></feather-icon>
                </b-button>
              </template>

<template #cell(barang)="{ item }">
                <strong>{{ item.barang ? item.barang.nama : "-" }}</strong>
              </template>
<template #cell(lokasi)="{ item }">
                {{ getLokasi(item) }}
              </template>
<template #cell(stok)="{ item }">
                {{ formatRupiah(item.stok) }}
              </template>
<template #cell(satuan)="{ item }">
                {{ item.barang ? item.barang.satuan.satuan : "-" }}
              </template>
<template #cell(nilai)="{ index, item }">
                <b-form-input v-if="item.isEdit" v-model="item.nilai" />
                <b-button v-else @click.prevent="edit(index)" variant="primary" size="sm">
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
              </template>
<template #cell(id_satuan)="{ index, item }">
                <v-select v-if="item.isEdit" v-model="item.id_satuan" :options="optkonversi" label="text" />
                <b-button v-else @click.prevent="edit(index)" variant="primary" size="sm">
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
              </template>
<template #cell(action)="{ index, item }">
                <b-form-input v-if="item.isEdit" v-model="item.jumlahAwal" />
                <b-button v-else @click.prevent="edit(index)" variant="primary" size="sm">
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
              </template>
</b-table>
</b-col> -->
          <!-- / -->

          <!-- List Stok yang siap dimutasi -->
          <b-col cols="12" class="mt-2 mb-3">
            <h5 class="mb-1">Daftar Mutasi</h5>
            <b-table small bordered striped responsive :fields="mutationFields" :items="mutations">
              <template #cell(no)="{ index }">
                {{ index + 1 }}
              </template>
              <template #cell(barang)="{ item }">
                <!-- <strong>{{ item.barang ? item.barang.nama : "-" }}</strong> -->
                {{ item.barang ? item.barang.varian : "-" }}<br>
                <small>{{ item.barang.kategori.kategori }}</small> /
                <small>
                  {{ item.barang ? item.barang.nama : "-" }}
                </small> <br>
              </template>
              <template #cell(lokasi)="{ item }">
                {{ getLokasi(item) }}
              </template>
              <template #cell(tujuan)>
                {{ form.tujuan ? form.tujuan.text : "-" }}
              </template>
              <template #cell(tujuan_stok_id)="{ item }">
                {{ getLokasi2(item.tujuan_stok_id) }}
                <!-- {{ tujuan_stok_id.blok ? tujuan_stok_id.blok.blok : "-" }} -->
              </template>
              <template #cell(jumlah)="{ item }">
                <b-form-input v-model="item.jumlah" type="number" />
                <small v-if="item.jumlah > item.jumlahAwal" class="text-danger">Jumlah mutasi melebihi stok dari gudang
                  asal</small>
              </template>
              <template #cell(satuan)="{ item }">
                <strong>{{ item.barang ? item.barang.satuan.satuan : "-" }}</strong>
              </template>
              <template #cell(satuan2)="{ item }">
                <strong>{{ item.barang ? item.barang.satuan.satuan : "-" }}</strong>
              </template>
              <template #cell(action)="{ index }">
                <b-button @click.prevent="mutations.splice(index, 1)" variant="danger" size="sm">
                  <feather-icon icon="XIcon"></feather-icon>
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <section class="d-flex justify-content-end">
          <b-button variant="primary" @click.prevent="submit2">
            Simpan Mutasi
          </b-button>
          <!-- <b-button :disabled="isIncompletedForm" variant="primary" @click.prevent="submit">
            Simpan Mutasi
          </b-button> -->
        </section>
      </b-overlay>
    </b-card>
  </main>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BOverlay,
  BTable,
  BButton,
  BFormInput,
  BFormTags,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BOverlay,
    BTable,
    BFormInput,
    BFormTags,
  },
  watch: {
    // "form.barang_id"(barang_id) {
    //   this.getKonversi(barang_id);
    // },
    // "item.nilai"(value2) {
    //   let hasil = value2 * item.id_satuan;
    //   item.jumlahAwal = hasil;
    // },
    // "item.id_satuan"(value) {
    //   let hasil = value * item.nilai;
    //   item.jumlahAwal = hasil;
    // },
    "form.barang_id"(barang_id) {
      if (barang_id) {
        this.getPenyimpananBarang(barang_id);
        // this.getKonversi(barang_id);
      }
    },
  },
  data: () => ({
    caribarang: [],
    optbloks: [],
    blok: null,
    blokasal: null,
    gudangutama: null,
    loading: false,
    form: {
      dari: null,
      tujuan: null,
      barang_id: null,
    },
    nilai: 0,
    hasil: 0,
    konversi: 0,
    jumlah: 0,
    barangs: [], // list barang
    availableStocks: [], // tampilkan avaialble stock barang
    mutations: [], // list stock barang yang siap dimutasikan
    mutationFields: [
      { key: "no", label: "No" },
      { key: "barang", label: "Barang" },
      { key: "lokasi", label: "Lokasi Awal" },
      { key: "jumlahAwal", label: "Stok Awal" },
      { key: "tujuan_stok_id", label: "Lokasi Tujuan" },
      { key: "jumlahTujuan", label: "Stok Tujuan" },
      { key: "jumlah", label: "Jumlah Mutasi" },
      // { key: "satuan", label: "Satuan" },
      // { key: "satuan2", label: "Satuan" },
      { key: "action", label: "#" },
    ],
    otherGudangs: [], // untuk tujuang gudang
    fields: [
      { key: "checkbox", label: "#" },
      { key: "barang", label: "Barang" },
      { key: "lokasi", label: "Lokasi" },
      { key: "stok", label: "Stok" },
      { key: "satuan", label: "Satuan" },
      // { key: "nilai", label: "Nilai" },
      // { key: "id_satuan", label: "Konversi" },
      { key: "action", label: "Stok Mutasi" },
    ],
  }),
  computed: {
    isIncompletedForm() {
      if (
        this.form.tujuan != null &&
        this.form.barang_id != null &&
        this.mutations.length > 0
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    async getKonversi(barang_id) {
      const optkonversi = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id,
      });
      this.optkonversi = [];
      optkonversi.forEach((konv) => {
        this.optkonversi.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
    },
    edit(index) {
      this.availableStocks[index].isEdit = true;
    },
    submit2() {
      // if (this.isIncompletedForm) {
      //   this.displayError({
      //     message: "Harap lengkapi form",
      //   });
      //   return false;
      // }

      // set payload
      let payload = [];
      this.mutations.map((mutation) => {
        payload.push({
          // dari: this.myGudang.id,
          // asal_stok_id: this.penyim
          tujuan_stok_id: mutation.tujuan_stok_id.id,
          // barang_id: mutation.barang ? mutation.barang.id : null,
          barang: mutation.barang,
          jumlah: mutation.jumlah,
          jumlahAwal: mutation.jumlahAwal,
          jumlahTujuan: mutation.jumlahTujuan,
          asal_stok_id: mutation.id,
          keterangan: mutation.keterangan
        });
      });

      let error = []
      payload.forEach(item => {
        const jumlah = parseInt(item.jumlah);  // Konversi jumlah ke integer
        const jumlahAwal = parseInt(item.jumlahAwal);  // Konversi jumlahAwal ke integer
        // Pengecekan apakah jumlah lebih besar dari jumlahAwal
        if (jumlah > jumlahAwal) {
          // Tampilkan pesan error untuk barang yang tidak valid
          error.push(
            {
              message: `Error: Jumlah ${jumlah} lebih besar dari jumlah awal ${jumlahAwal} untuk produk dengan nama ${item.barang.nama} - ${item.barang.varian}.`
            })
          // return false

          //     console.error(`Error: Jumlah ${jumlah} lebih besar dari jumlah awal ${jumlahAwal} untuk barang dengan ID ${item.barang_id}.`);
          // alert(`Error: Jumlah ${jumlah} melebihi jumlah stok awal ${jumlahAwal} untuk barang dengan nama ${item.barang.nama} - ${item.barang.varian}.`);
        } else {
          // Jika valid, lakukan proses lainnya
          this.displaySuccess({
            message: `Berhasil menambahkan produk yang akan dimutasi dengan nama ${item.barang.nama} - ${item.barang.varian}.`
          })
          // console.log(`Proses valid untuk barang dengan nama ${item.barang.nama} - ${item.barang.varian}.`);
        }
      });

      // console.log('CEKKKKK', payload)
      if (error.length > 0) {
        error.forEach(item => {
          this.displayWarning(item)
        })
        return false
      }
      this.loading = true;
      this.$store
        .dispatch("mutasi/saveToko", payload)
        .then(() => {
          this.displaySuccess({
            message: "Mutasi berhasil disimpan!",
          });
          setTimeout(() => {
            this.$router.push("/mutasi");
          }, 1000);
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });


    },
    submit() {
      if (this.isIncompletedForm) {
        this.displayError({
          message: "Harap lengkapi form",
        });
        return false;
      }

      // set payload
      let payload = [];
      this.mutations.map((mutation) => {
        payload.push({
          // dari: this.myGudang.id,
          // asal_stok_id: this.penyim
          tujuan_stok_id: mutation.tujuan_stok_id,
          barang_id: mutation.barang ? mutation.barang.id : null,
          jumlah: mutation.jumlahAwal,
          asal_stok_id: mutation.id,
          keterangan: mutation.keterangan
        });
      });

      this.loading = true;
      this.$store
        .dispatch("mutasi/save", payload)
        .then(() => {
          this.displaySuccess({
            message: "Mutasi berhasil disimpan!",
          });
          setTimeout(() => {
            this.$router.push("/mutasi");
          }, 1000);
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getLokasi(stock) {
      let lokasi = [];
      if (stock.gudang) {
        lokasi.push(`Gudang: ${stock.gudang.nama_gudang}`);
      }
      if (stock.palet) {
        lokasi.push(`Palet: ${stock.palet.palet}`);
      }
      if (stock.blok) {
        lokasi.push(`Blok: ${stock.blok.blok}`);
      }
      if (stock.rak) {
        lokasi.push(`Rak: ${stock.rak.rak}`);
      }
      if (stock.laci) {
        lokasi.push(`Laci: ${stock.laci.laci}`);
      }

      return lokasi.join(", ");
    },
    getLokasi2(stock) {
      let lokasi2 = [];
      // console.log('lokasi2', stock)
      if (stock.gudang) {
        lokasi2.push(`Gudang: ${stock.gudang.nama_gudang}`);
      }
      if (stock.palet) {
        lokasi2.push(`Palet: ${stock.palet.palet}`);
      }
      if (stock.blok) {
        lokasi2.push(`Blok: ${stock.blok.blok}`);
      }
      if (stock.rak) {
        lokasi2.push(`Rak: ${stock.rak.rak}`);
      }
      if (stock.laci) {
        lokasi2.push(`Laci: ${stock.laci.laci}`);
      }

      return lokasi2.join(", ");
    },
    addBarang(stock) {
      this.mutations.push(stock);
      console.log('ccek', stock)
    },
    getPenyimpananBarang() {
      if (this.blok == null || this.blokasal == null) {
        this.displayWarning({
          message: 'Harap form lokasi asal dan tujuan mutasi dipilih'
        })
        this.caribarang = null
        this.caribarang = []
        return false
      }
      this.loading = true;
      let params = {
        kode_barang: this.caribarang.join(","),
      };
      if (this.gudangutama) {
        params.gudang_id = this.gudangutama.value;
      }
      this.$store
        .dispatch("tokopenyimpanan/getData", params)
        .then((stocks) => {
          if (this.blok) {
            // console.log("AJI", this.blok)
            const filteredData = stocks.filter(data => data.blok_id == this.blokasal.value);
            const filteredSama = stocks.filter(data => data.blok_id == this.blok.value);
            filteredData.map((stock) => {
              stock.isEdit = false;
              stock.jumlahAwal = stock.jumlah;
              stock.jumlah = 0;
              stock.satuan = stock.satuan;
              stock.tujuan_stok_id = filteredSama[0];
              stock.jumlahTujuan = filteredSama[0].jumlah;
            });
            // console.log("AJI", filteredData)
            this.loading = false;
            this.availableStocks = JSON.parse(JSON.stringify(filteredData));
            if (this.availableStocks.length == 1) {
              this.addBarang(this.availableStocks[0]);

              // console.log("AJI", filteredData)
              this.caribarang = null
              // Atur availableStocks menjadi null atau []
              this.availableStocks = null; // Atau this.availableStocks = [];
            }

            // Jika diperlukan, Anda juga bisa memaksa availableStocks menjadi [] di akhir
            if (!this.availableStocks) {
              this.availableStocks = []; // Atau null sesuai kebutuhan
              this.caribarang = []
            }
            // filteredData = []
            // filteredSama = []
          } else {
            stocks.map((stock) => {
              stock.isEdit = false;
              stock.jumlahAwal = stock.jumlah;
              stock.satuan = stock.satuan;
            });
            this.loading = false;
            this.availableStocks = JSON.parse(JSON.stringify(stocks));
          }
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    async getBarangs() {
      const barangs = await this.$store.dispatch("barang/getData");
      barangs.map((barang) => {
        this.barangs.push({
          value: barang.id,
          text: barang.nama,
        });
      });
    },
    async getGudangs() {
      const gudangs = await this.$store.dispatch("gudang/getData", { jenis: 1 });
      gudangs.map((gudang) => {
        this.otherGudangs.push({
          text: gudang.nama_gudang,
          value: gudang.id,
        })
        this.gudangutama = this.otherGudangs[0]
        // this.otherGudangs = response
        // this.otherGudangs.push({
        //   text: gudang.nama_gudang,
        //   value: gudang.id,
        // })
        // this.gudangutama = this.otherGudangs[0]
        // if (gudang.id != this.myGudang.id) {
        // this.otherGudangs.push({
        //   value: gudang.id,
        //   text: gudang.nama_gudang,
        // });
        // }
      });
      this.getBloks(gudangs[0].id)
    },
    async getBloks(item) {
      const bloks = await this.$store.dispatch("tokoblok/getData", { gudang_id: item });
      bloks.map((gudang) => {
        // this.otherGudangs = response
        this.optbloks.push({
          text: gudang.blok,
          value: gudang.id,
        })
        // this.blok = this.optbloks

        // if (gudang.id != this.myGudang.id) {
        // this.otherGudangs.push({
        //   value: gudang.id,
        //   text: gudang.nama_gudang,
        // });
        // }
      });
    },
  },
  created() {
    // if (!this.isAdminGudang) {
    //   this.$router.push("/");
    // }
    // this.getBarangs();
    this.getGudangs();
  },
};
</script>
